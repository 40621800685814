import React from 'react';
import AssetHierarchy from './AssetHierarchy';

function App() {
  return (
    <div className="App">
      <AssetHierarchy />
    </div>
  );
}

export default App;
