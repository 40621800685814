import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const AssetHierarchy = () => {
  const hierarchy = {
    cabinets: {
      woodgrain: [
        { name: 'Dark Woodgrain', image: 'https://firebasestorage.googleapis.com/v0/b/fir-template-mc.appspot.com/o/world.png?alt=media&token=132c6dc7-e064-4534-831a-8e99463570db' },
        { name: 'Light Woodgrain', image: 'https://firebasestorage.googleapis.com/v0/b/fir-template-mc.appspot.com/o/darkwoodshelf.jpeg?alt=media&token=005763eb-5016-4d58-b4c2-415b119d83c0' }
      ],
      color: [
        { name: 'White Cabinet', image: 'https://firebasestorage.googleapis.com/v0/b/fir-template-mc.appspot.com/o/whitecabinet.jpeg?alt=media&token=eaa1b49e-775d-4c23-9875-59a63fa0ce32' },
        { name: 'Black Cabinet', image: 'https://firebasestorage.googleapis.com/v0/b/fir-template-mc.appspot.com/o/blackcabinet.jpeg?alt=media&token=1506f0f1-0eba-43c7-8a68-1b57c7f545c5' }
      ]
    },
    shelves: {
      metal: [
        { name: 'Metal Shelf', image: 'https://firebasestorage.googleapis.com/v0/b/fir-template-mc.appspot.com/o/metalshelf.jpeg?alt=media&token=9bc670e3-ed61-43c9-839a-bc39a8c80c40' }
      ],
      wood: [
        { name: 'Wood Shelf', image: 'https://firebasestorage.googleapis.com/v0/b/fir-template-mc.appspot.com/o/woodshelf.jpeg?alt=media&token=cb9aa3c7-8421-4d09-a901-6adbcf81558c' }
      ]
    }
  };

  const [mainCategory, setMainCategory] = useState('');
  const [subcategory, setSubcategory] = useState('');
  const [assets, setAssets] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState('');
  const [image, setImage] = useState('');

  const handleMainCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    setMainCategory(selectedCategory);
    setSubcategory('');
    setAssets([]);
    setImage('');
  };

  const handleSubcategoryChange = (e) => {
    const selectedSub = e.target.value;
    setSubcategory(selectedSub);
    setAssets(hierarchy[mainCategory][selectedSub]);
    setImage('');
  };

  const handleAssetChange = (e) => {
    const selectedImage = e.target.value;
    setSelectedAsset(selectedImage);
    setImage(selectedImage);
  };

  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4">Select an Asset</h1>

      <div className="form-group mb-3">
        <label htmlFor="mainCategory">Category:</label>
        <select
          id="mainCategory"
          className="form-control"
          value={mainCategory}
          onChange={handleMainCategoryChange}
        >
          <option value="" disabled>Select Category</option>
          <option value="cabinets">Cabinets</option>
          <option value="shelves">Shelves</option>
        </select>
      </div>

      {mainCategory && (
        <div className="form-group mb-3">
          <label htmlFor="subcategory">Subcategory:</label>
          <select
            id="subcategory"
            className="form-control"
            value={subcategory}
            onChange={handleSubcategoryChange}
          >
            <option value="" disabled>Select Subcategory</option>
            {Object.keys(hierarchy[mainCategory]).map((sub, index) => (
              <option key={index} value={sub}>
                {sub.charAt(0).toUpperCase() + sub.slice(1)}
              </option>
            ))}
          </select>
        </div>
      )}

      {subcategory && (
        <div className="form-group mb-3">
          <label htmlFor="asset">Choose Asset:</label>
          <select
            id="asset"
            className="form-control"
            value={selectedAsset}
            onChange={handleAssetChange}
          >
            <option value="" disabled>Select Asset</option>
            {assets.map((asset, index) => (
              <option key={index} value={asset.image}>
                {asset.name}
              </option>
            ))}
          </select>
        </div>
      )}

      {image && (
        <div className="image-container text-center mt-4">
          <img
            src={image}
            alt="Selected Asset"
            className="img-fluid"
            style={{ maxWidth: '100%', borderRadius: '10px' }}
          />
        </div>
      )}
    </div>
  );
};

export default AssetHierarchy;
